import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FingoNavbar from '@fingo/lib/components/layout/FingoNavbar';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import useDynamicFingoLogo from '@fingo/lib/hooks/useDynamicFingoLogo';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import useIsLogged from '@fingo/lib/hooks/useIsLogged';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import { styled } from '@mui/styles';
import LoggedOutMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedOutMobileNavItems';
import LoggedInMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedInMobileNavItems';
import SelectCompanyNavItem from '@fingo/lib/components/company/SelectCompanyNavItem';
import { useFingoRoutes, useShowingTyC } from '../../../hooks';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MobileNavbar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routes = useFingoRoutes().filter(({ showInNavbar }) => showInNavbar);
  const isLogged = useIsLogged();
  const [drawerOpen, toggleDrawer] = useBooleanState(false);
  const selectedCompany = useSelectedCompany();
  const user = useGetUser();
  const showTyc = useShowingTyC();
  const fingoLogo = useDynamicFingoLogo();

  const handleClick = () => {
    if (isLogged) {
      history.push('/app/home');
    } else {
      window.location.replace(t('landing-path'));
    }
  };

  return (
    <>
      <FingoNavbar>
        <Stack
          direction="row"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            onClick={handleClick}
            width="25%"
            height={35}
          >
            <img
              src={fingoLogo}
              alt="logo"
              style={{ width: 90, height: 35, objectFit: 'contain' }}
            />
          </Box>
          <Stack
            width="75%"
            direction="row"
            alignItems="center"
            flexGrow="1"
            justifyContent="flex-end"
          >
            {selectedCompany && <SelectCompanyNavItem ml={0} />}
            {!showTyc && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                id="mobile-nav-toggle"
              >
                <Menu color="primary" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </FingoNavbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 280,
            bgcolor: 'text.primary',
            pb: 4,
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer} id="mobile-nav-close">
            <Close sx={{ color: 'common.white' }} />
          </IconButton>
        </DrawerHeader>
        {isLogged && user && (
          <LoggedInMobileNavItems closeDrawer={toggleDrawer} routes={routes} />
        )}
        {!isLogged && <LoggedOutMobileNavItems />}
      </Drawer>
    </>
  );
};

export default MobileNavbar;
